import { Box, Grid, Typography } from '@mui/material';
import { styled } from '@mui/styles';
import CurrencyNumber from 'Components/CurrencyNumber';
import React from 'react';

const CardFieldTitle = styled(Typography)(({ theme }) => ({
    fontWeight: "500 !important",
    color: "#808080 !important",
    fontFamily: "Work Sans !important",
    fontSize: "12px !important"
}));
const CardFieldContent = styled(Typography)(({ theme }) => ({
    fontFamily: "Work Sans !important",
    fontSize: "14px !important",
    minHeight: "14px"
}));

const ReceiptCard: React.FunctionComponent<{
    periodStart: string,
    periodEnd: string,
    amount: number,
    status: string,
    index: number,
    isShowStar: boolean
}> = ({
    periodStart,
    periodEnd,
    amount,
    status,
    index,
    isShowStar = false
}) => {
        return (
            <Box sx={{
                height: "90px",
                padding: "16px 0",
                borderBottom: "solid 1px",
                borderTop: index === 0 ? "solid 1px" : "none"
            }}
            >
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <CardFieldTitle>Period start</CardFieldTitle>
                        <CardFieldContent>{periodStart}</CardFieldContent>
                    </Grid>
                    <Grid item xs={6}>
                        <CardFieldTitle>Period end</CardFieldTitle>
                        <CardFieldContent>{periodEnd}</CardFieldContent>
                    </Grid>
                    <Grid item xs={6}>
                        <CardFieldTitle>Amount</CardFieldTitle>
                        <CardFieldContent><CurrencyNumber value={amount} />{isShowStar && "*"}</CardFieldContent>
                    </Grid>
                    <Grid item xs={6}>
                        <CardFieldTitle>Status</CardFieldTitle>
                        <CardFieldContent>{status}</CardFieldContent>
                    </Grid>
                </Grid>
            </Box>
        )
    }

export default ReceiptCard