import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import { makeStyles } from "@mui/styles";
import Field from "@mui/material/TextField";
import { useFormik } from "formik";
import React, { useEffect, useMemo, useState, useContext } from "react";
import Button from "Components/base/Button/Button";
import style from "./style.module.scss";
import { formSchema } from "./formSchema";
import { gameServerApi } from "services/gameServerV2";
import { useHistory, useParams } from "react-router-dom";
import { AvironLoading } from "Components/Icons/AvironLoading";
import { CONSTANTS } from "consts";
import Swal from "sweetalert2";
import ErrorIcon from "Components/Icons/ErrorIcon.svg";
import SuccessInvite from "Components/Icons/SuccessInvite.svg";
import InputOutline from "Components/base/InputOutline";
import SelectField from "Components/FormElements/Select";
import InputLabel from "Components/base/InputLabel";
import { errorAddMachineCode } from "services/gameServerV2/common/machine";
import { useGetAvironSubscriptionQuery, useTrackingSubscriptionAllActionMutation } from "services/webServer";
import { UserAppContext } from "contexts";

interface InviteMemberProps {
    open: boolean;
    onClose: any;
    refetchData?: any;
}

const useStyles: any = makeStyles({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
});

const AddMachineModal = (props: InviteMemberProps) => {
    const { open, onClose, refetchData } = props;
    const [trackingSubscriptionAllAction] =
    useTrackingSubscriptionAllActionMutation();
    const { auth } = useContext(UserAppContext);
    const userId = useMemo(() => auth?.id, [auth]);



    const classes = useStyles();
    const params: any = useParams();
    const [showAvironLoading, setShowAvironLoading] = useState(false);
    const machineTypeOption = [
      {
        name: "Bike",
        value: CONSTANTS.APP.AVIRON_BIKE,
      },
      {
          name: "Rower",
          value: CONSTANTS.APP.AVIRON_APK,
      },
      {
        name: "Treadmill",
        value: CONSTANTS.APP.AVIRON_TREADMILL,
    },
    ];
    const history = useHistory();

    const initialValues = {
        appId: CONSTANTS.APP.AVIRON_APK,
        machineId: "",
    };

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: formSchema,
        onSubmit: async (values) => {
          await handleSubmitForm(values);
        },
    });

    const {data: subscriptionData} = useGetAvironSubscriptionQuery({
        variables:{
            id: params?.id
        },
        skip: !params?.id
    })

    const handleTrackingAddMachine =  ({machineId, status, reason}:{machineId: string, status: string, reason?: string}) => {
        const reasonText = status === "failed" ? {reason} : {}
        trackingSubscriptionAllAction({
            variables:{
                id: params?.id,
                action: "add_machine",
                payload:{
                    ...reasonText,
                    customer_id: userId,
                    machine: machineId,
                    customer_type: "user",
                    source: "cms",
                    status: status,
                    plan_id: subscriptionData?.avironSubscription?.planId
                },
                historyType: "conversion",
            }
        })
    }

    const errorHanlde = async ({error, machineId}:{error: any, machineId: string}) =>{
        if (error?.response?.data?.error?.code === errorAddMachineCode.PRODUCT_NOT_FOUND_CODE) {
            handleTrackingAddMachine({machineId, status: "failed", reason: "Product not found"})

            onClose();
            const errorMsg = error?.response?.data?.error?.message;
            if(errorMsg === errorAddMachineCode.MACHINE_NOT_FOUND) {
                Swal.fire({
                    title: "Product not found",
                    html: "<div>Please double-check the Machine ID and try again.</div>",
                    imageUrl: ErrorIcon,
                    showClass: {
                        popup: `
                              error-invite-member-popup
                            `,
                    },
                });
            } else if(errorMsg === errorAddMachineCode.MACHINE_ALREADY_EXISTS) {
                Swal.fire({
                    title: "Product Activated",
                    html: "<div>A membership already exists. Please contact support@avironactive.com for assistance.</div>",
                    imageUrl: ErrorIcon,
                    showClass: {
                        popup: `
                              error-invite-member-popup
                            `,
                    },
                });
            } else {
                Swal.fire({
                    title: "Error",
                    html: `<div>${errorMsg}</div>`,
                    imageUrl: ErrorIcon,
                    showClass: {
                        popup: `
                              error-invite-member-popup
                            `,
                    },
                });
            }
        } else if (error?.response?.data?.error?.code === errorAddMachineCode.PRODUCT_LIMIT_NUMBER_CODE) {
            handleTrackingAddMachine({machineId, status: "failed", reason: "Maximum number of products connected"})
            onClose();
            const res = await Swal.fire({
                title: "ERROR",
                html: "<div>Maximum number of products connected. Upgrade your membership to add more products.</div>",
                imageUrl: ErrorIcon,
                showClass: {
                    popup: `
                          error-invite-member-popup
                        `,
                },
                confirmButtonText: "Upgrade membership",
            });
            if(!res.isConfirmed) {
                return;
            }
            history.push(CONSTANTS.NAVIGATION_URL.MEMBERSHIPS + `/${params?.id}`);
        } else if (error?.response?.data?.error?.code === errorAddMachineCode.PRODUCT_EXIST_CODE) {
            handleTrackingAddMachine({machineId, status: "failed", reason: "Machine ID has already been added to this membership"})
            formik.setFieldError('machineId', 'This Machine ID has already been added to this membership.')
        } else {
            handleTrackingAddMachine({machineId, status: "failed", reason: error?.response?.data?.error?.message})
            onClose();
            Swal.fire({
                title: "Error",
                html: `<div>${
                    error?.response?.data?.error?.message}</div>`,
                imageUrl: ErrorIcon,
                showClass: {
                    popup: `
                          error-invite-member-popup
                        `,
                },
            });
        }
    }

    const handleSubmitForm = async (values: { appId: number, machineId: string }) => {
        try {
            setShowAvironLoading(true);
            const {appId, machineId} = values
            await gameServerApi.machine.addMembershipMachine({
                //@ts-ignore
                orgId: params?.id,
                machineId: machineId,
                appId: appId,
            });
            handleTrackingAddMachine({machineId, status: "success"})
            onClose();
            setShowAvironLoading(false);
            await refetchData();
            setTimeout(() => {
                Swal.fire({
                    title: "Success",
                    html: `<div>The product with Machine ID: ${machineId} has been added.</div>`,
                    imageUrl: SuccessInvite,
                    showClass: {
                        popup: `error-invite-member-popup`,
                    },
                });
            }, 600);
        } catch (error) {
            setShowAvironLoading(false);
            await errorHanlde({error, machineId: values.machineId});
        } finally {
            setShowAvironLoading(false);
        }
    };

    const handleClickCancel = () => {
        onClose && onClose();
        formik.resetForm()
    }

    React.useEffect(() => {
        if(!open) {
            formik.resetForm();
        }
    }, [open]);

    return (
        <>
            <Modal
                open={open}
                onClose={onClose}
                className={classes.modal + " " + style["modalCustom"]}
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 800,
                }}
            >
                <Fade in={open}>
                    <div className={style["change-password-modal"]}>
                        <div className={style["close-btn"]} onClick={onClose}>
                            <img
                                src="/images/icons/x-icon.svg"
                                width={18}
                                height={18}
                                alt="icon"
                            />
                        </div>

                        <div className={style["titleModal"]}>
                            Add new product
                        </div>
                        {/* <div className={style["desc"]}>
                            Replace this product with a different one to start using your membership on the new product
                        </div> */}
                        <Box>
                            <form onSubmit={formik.handleSubmit}>
                                <div className={style["text-field-group"]}>
                                    <InputLabel htmlFor="outlined-machine-type">
                                        Please choose the type of product
                                    </InputLabel>
                                    <SelectField
                                        options={machineTypeOption}
                                        value={formik.values.appId}
                                        onChange={formik.handleChange}
                                        name={"appId"}
                                    />
                                </div>
                                <div className={style["text-field-group"]}>
                                    <InputOutline
                                        label="Please enter the Machine ID of the product"
                                        name="machineId"
                                        value={formik.values.machineId}
                                        onChange={formik.handleChange}
                                        error={formik.errors.machineId}
                                        placeholder={'Machine ID'}
                                    />
                                </div>
                                <div className={style["buttons-group"]}>
                                    <Button
                                        type="submit"
                                        variant="outlined"
                                        fullWidth
                                        sx={{
                                            height: "52px",
                                        }}
                                        className={style["cancleButton"]}
                                        onClick={handleClickCancel}
                                    >
                                        Exit
                                    </Button>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="secondary"
                                        fullWidth
                                        sx={{
                                            height: "52px",
                                        }}
                                    >
                                        Add
                                    </Button>
                                </div>
                            </form>
                        </Box>
                    </div>
                </Fade>
            </Modal>
            {showAvironLoading && <AvironLoading />}
        </>
    );
};

export default AddMachineModal;
