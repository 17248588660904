import Close from "@mui/icons-material/Close";
import {
    Backdrop,
    Box,
    Button,
    IconButton,
    Modal,
    Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import "./style.scss";

const useStyles: any = makeStyles({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
});

const offers = [
    {
        image: "/images/membership/monthly-cancel-offer.jpg",
        description: (
            <>
                We have something special just for you. <br />
                Today only, get <b>50% off</b> your Monthly Membership for 2
                months.
            </>
        ),
        availableFor: [1, 49, 46, 56],
    },
    {
        image: "/images/membership/annual-cancel-offer.jpg",
        description: (
            <>
                We have something special just for you. <br />
                Today only, get <b>25% off</b> your Annual Membership for 1
                year.
            </>
        ),
        availableFor: [2, 4, 50, 47, 57],
    },
];

const CancelOfferMembershipModal: React.FunctionComponent<{
    planId?: number;
    open: boolean;
    onClose: any;
    onSubmit: () => void;
    onCancel: () => void;
}> = ({ open, onClose, onSubmit, planId = 1, onCancel }) => {
    const classes = useStyles();
    const offer = React.useMemo(() => {
        return offers.find((item) => {
            return item.availableFor.includes(planId);
        });
    }, [planId]);

    const handleSubmit = (event: any) => {
        event.preventDefault();
        onSubmit();
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            className={classes.modal}
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            closeAfterTransition
            BackdropComponent={Backdrop}
            // BackdropProps={{
            //     timeout: 500,
            // }}
        >
            <div className="cancel-offer-modal-component">
                <IconButton onClick={onClose} className="close-button">
                    <Close></Close>
                </IconButton>
                <div className="cancel-offer-modal-component__main">
                    <Box className="cancel-offer-modal-component__main__item">
                        <img src={offer?.image} alt='img' />
                    </Box>
                    <Box
                        textAlign={"center"}
                        className="cancel-offer-modal-component__main__item cancel-offer-modal-component__main__item--content"
                    >
                        <div>
                            <Typography
                                component="h1"
                                variant="h1"
                                mb="12px"
                                style={{
                                    letterSpacing: 5,
                                    fontFamily: "GRIFTER",
                                    fontWeight: 700,
                                    fontSize: 44,
                                }}
                            >
                                BEFORE <br /> YOU GO
                            </Typography>
                            <Typography fontSize={"16px"}>
                                {offer?.description}
                            </Typography>
                            <Button
                                className="btn-claim-offer"
                                variant="contained"
                                onClick={handleSubmit}
                                color="secondary"
                                fullWidth
                                sx={{
                                    marginTop: "24px",
                                    marginBottom: "24px",
                                }}
                            >
                                CLAIM OFFER
                            </Button>
                            <br />
                            <Typography variant="button" fontSize={"16px"}>
                                <u
                                    style={{
                                        cursor: "pointer",
                                    }}
                                    onClick={onCancel}
                                >
                                    Cancel membership
                                </u>
                            </Typography>
                        </div>
                    </Box>
                </div>
            </div>
        </Modal>
    );
};

export default CancelOfferMembershipModal;
