export const hideExternalIcons = (element: any, idElement?: boolean) => {
  if (element) {
    if (idElement) {
      element.classList.add('hideExternalLink')
    } else {
      for (let i = 0; i < element.length; i++) {
          element[i].classList.add('hideExternalLink')
      }
    }
  }
}