export enum MembershipProductType {
    membership = "membership",
    warranty = "warranty",
    membershipWithWarranty = "membership-with-warranty",
    membershipWithFinance = "membership-financing",
    membershipWithWarrantyAndFinance = "membership-warranty-financing",
}

export enum MEMBERSHIP_INTERVAL {
    MONTHLY = "monthly",
    YEARLY = "yearly",
    WEEKLY = "weekly",
    LIFETIME = "lifetime",
}

export enum MEMBERSHIP_TRACKING_TYPE {
    MONTHLY = "monthly",
    ANNUAL = "annual",
}
