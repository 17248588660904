import React, { memo, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import HistoryItem from "./HistoryItem";
import { WorkoutHistoriesItemType } from "Screens/WorkoutHistory/Main/types";
import CustomScrollBar from "Components/base/ScrollBarCustom";
import style from "./HistorySection.module.scss";
import { ReactComponent as EmptyListIcon } from "./EmptyIcon.svg";
import useWindowSize from "Hooks/useWindowSize";
import { CONSTANTS } from "consts";
import AvironBikeHistoryItem from "./AvironBikeHistoryItem";
var dayjsLib = require("dayjs");
var utc = require("dayjs/plugin/utc");
dayjsLib.extend(utc);

type MetaDataType = {
    currentPage: number;
    firstPage: number;
    lastPage: number;
    pageSize: number;
    totalRecords: number;
};

// type PropsType = {
//     historyData: WorkoutHistoriesItemType[];
//     currentSelect: number | string | null;
//     handleSelect: (id: string) => void;
// metaData: MetaDataType;
//     handleNextPage: () => void;
//     handlePrevPage: () => void;
//     handleLastPage: () => void;
//     handleFirstPage: () => void;
//     ToolbarTop?: any;
//     loading?: boolean;
//     showWorkoutDateOnTitle?: boolean;
// };

const HistorySection = (props: any) => {
    const {
        currentSelect,
        handleSelect,
        metaData,
        historyData,
        handleNextPage,
        handlePrevPage,
        handleFirstPage,
        handleLastPage,
        onOpenWorkoutPopup,
        ToolbarTop,
        loading,
        showWorkoutDateOnTitle,
    } = props;
    const windowSize = useWindowSize();
    const appId = localStorage.getItem("appId");
    const isAvironBike = appId === String(CONSTANTS.APP.AVIRON_BIKE);
    const isBikeMachine = [CONSTANTS.APP.C2_BIKEERG, CONSTANTS.APP.AVIRON_BIKE].includes(Number(appId));
    const isAvironTreadmill = appId === String(CONSTANTS.APP.AVIRON_TREADMILL);
    const [heightContentScrollHistory, setHeightContentScrollHistory] =
        useState(0);
    useEffect(() => {
        // debugger
        if (historyData && historyData.length > 0) {
            const contenHistoryWrapperElement = document.getElementById(
                "content_history_wrapper"
            );
            if (contenHistoryWrapperElement) {
                const newHeight = contenHistoryWrapperElement.offsetHeight;
                setHeightContentScrollHistory(newHeight);
            }
            if(windowSize.width >=1024) {
                handleSelect(historyData[0]["id"]);
            }
        }
        // else {
        //     handleSelect(null)
        // }
    }, [historyData]);
    React.useEffect(() => {
        function handleResize() {
            const heightChange = document.getElementById(
                "content_history_wrapper"
            );
            if (heightChange) {
                setHeightContentScrollHistory(heightChange.offsetHeight);
            }
        }

        window.addEventListener("resize", handleResize);
    });
    // const { timezone } = React.useContext(UserAppContext);
    // const timeZone = timezone?.timezone;
    const getWorkoutTime = (item: any) => {
        if (item && item?.createTimeTzUser) {
            const dateTime = dayjsLib.utc(item?.createTimeTzUser * 1000);
            if (dateTime) {
                const month = dateTime?.format("MMM");
                const day = dateTime?.format("DD");
                const year = dateTime?.format("YYYY");
                return `${month} ${day} ${year}`;
            } else {
                return "";
            }
        } else {
            return "";
        }
    };
    const getWorkoutListIcon = () => {
        if(isAvironBike) {
            return "/images/icons/bike-machine.svg"
        } else if(isAvironTreadmill) {
            return "/images/icons/treadmill-machine.svg"
        } else {
            return "/images/icons/rowing-machine.svg"
        }
    }
    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            className={
                style["history-section-component"] +
                " history-section-component"
            }
        >
            <div className={style["title-container"]}>
                <div className={style["icon-container"]}>
                    <img src={getWorkoutListIcon()}
                         alt="img" 
                     />
                </div>
                <div className={style["title-text"]}>
                    WORKOUT LIST
                    {historyData && showWorkoutDateOnTitle ? (
                        <div className={style["workoutDateTime"]}>
                            {getWorkoutTime(historyData[0])}
                        </div>
                    ) : (
                        ""
                    )}
                </div>
            </div>
            <></>
            <div className={style["history_toolbar_top"]}>
                {ToolbarTop && <>{ToolbarTop}</>}
            </div>
            <div
                className={`${style["content_history_wrapper"]} ${
                    ToolbarTop ? "" : "content_history_wrapper_not_haveToolBar"
                }`}
                id={"content_history_wrapper"}
            >
                {historyData && historyData.length === 0 && !loading ? (
                    <div className={style["emptyHistoryWrapper"]}>
                        <div className={style["emptyHistoryContent"]}>
                            <div className={style["icon"]}>
                                <EmptyListIcon />
                            </div>
                            <div className={style["text"]}>
                                Your workout list is empty
                            </div>
                        </div>
                    </div>
                ) : (
                    <>
                        {windowSize.width > 1023 &&
                            heightContentScrollHistory > 0 && (
                                <CustomScrollBar
                                    heightWrapper={heightContentScrollHistory}
                                    idParent={"content_history_wrapper"}
                                >
                                    <div className={style["items_content"]}>
                                        {historyData &&
                                            historyData.map(
                                                (
                                                    item: WorkoutHistoriesItemType
                                                ) => (
                                                    <>
                                                        {(isAvironBike || isAvironTreadmill) ? (
                                                            <AvironBikeHistoryItem
                                                                key={item.id}
                                                                item={item}
                                                                currentSelect={
                                                                    currentSelect
                                                                }
                                                                handleSelect={(
                                                                    id: string
                                                                ) =>
                                                                    handleSelect(
                                                                        id
                                                                    )
                                                                }
                                                            />
                                                        ) : (
                                                            <HistoryItem
                                                                key={item.id}
                                                                item={item}
                                                                currentSelect={
                                                                    currentSelect
                                                                }
                                                                handleSelect={(
                                                                    id: string
                                                                ) =>
                                                                    handleSelect(
                                                                        id
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    </>
                                                )
                                            )}
                                    </div>
                                </CustomScrollBar>
                            )}
                        {windowSize.width <= 1023 && (
                            <div className={style["items_content"]}>
                                {historyData &&
                                    historyData.map(
                                        (item: WorkoutHistoriesItemType) => (
                                            <>
                                                {(isAvironBike || isAvironTreadmill) ? (
                                                    <AvironBikeHistoryItem
                                                        key={item.id}
                                                        item={item}
                                                        currentSelect={
                                                            currentSelect
                                                        }
                                                        handleSelect={(
                                                            id: string
                                                        ) => handleSelect(id)}
                                                        onOpenWorkoutPopup={
                                                            onOpenWorkoutPopup
                                                        }
                                                    />
                                                ) : (
                                                    <HistoryItem
                                                        key={item.id}
                                                        item={item}
                                                        currentSelect={
                                                            currentSelect
                                                        }
                                                        handleSelect={(
                                                            id: string
                                                        ) => handleSelect(id)}
                                                        onOpenWorkoutPopup={
                                                            onOpenWorkoutPopup
                                                        }
                                                    />
                                                )}
                                            </>
                                        )
                                    )}
                            </div>
                        )}
                    </>
                )}
            </div>
            {/* footer */}
            <Box
                display="flex"
                justifyContent="space-evenly"
                alignItems="center"
                className={style["history_footer"]}
            >
                <IconButton
                    onClick={handleFirstPage}
                    style={{
                        backgroundColor: "white",
                        fontSize: "15px",
                    }}
                    disabled={metaData?.currentPage === 1}
                >
                    <KeyboardDoubleArrowLeftIcon />
                </IconButton>
                <IconButton
                    onClick={handlePrevPage}
                    style={{
                        backgroundColor: "white",
                        fontSize: "15px",
                    }}
                    disabled={metaData?.currentPage === 1}
                >
                    <KeyboardArrowLeftIcon />
                </IconButton>
                <Box>
                    <Box
                        fontSize={15}
                        textAlign="center"
                        className={style["textPaging"]}
                    >
                        {`${metaData?.currentPage || "---"} OF ${
                            metaData?.lastPage || "---"
                        }`}
                    </Box>
                </Box>
                <IconButton
                    onClick={handleNextPage}
                    style={{
                        backgroundColor: "white",
                        fontSize: "15px",
                    }}
                    disabled={metaData?.currentPage === metaData?.lastPage}
                >
                    <KeyboardArrowRightIcon />
                </IconButton>
                <IconButton
                    onClick={handleLastPage}
                    style={{
                        backgroundColor: "white",
                        fontSize: "15px",
                    }}
                    disabled={metaData?.currentPage === metaData?.lastPage}
                >
                    <KeyboardDoubleArrowRightIcon />
                </IconButton>
            </Box>
        </Box>
    );
};

export default memo(HistorySection);
