import { Backdrop, Button, Fade, IconButton, Modal } from "@mui/material";
import React, {
    Ref,
    forwardRef,
    useContext,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from "react";
import { makeStyles } from "@mui/styles";
import Close from "@mui/icons-material/Close";

import { UserAppContext } from "contexts";
import {
    useReactiveUserSubscriptionV2Mutation,
    useRetriveStripeCustomerQuery,
    useSharedQuery,
} from "services/webServer";
import get from "lodash/get";
import MembershipOfferInvalidPaymentModal, {
    IMembershipOfferInvalidPaymentModalRef,
} from "../MembershipOfferInvalidPaymentModal/MembershipOfferInvalidPaymentModal";
import MembershipOfferClaimedModal, {
    IMembershipOfferClaimedModalRef,
} from "../MembershipOfferClaimedModal/MembershipOfferClaimedModal";

import { useHideExternalIcon } from "Hooks/useHideExternalIcon";
import { MEMBERSHIP_OFFER_KEY } from "../MembershipsMain.constant";
import styles from "./MembershipOfferTwoMonthsModal.module.scss";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
    modal: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
});

interface IMembershipOfferTwoMonthsModalProps {
    membershipId: string;
}

export interface IMembershipOfferTwoMonthsModalRef {
    setIsVisible: (visible: boolean) => void;
    isVisible: boolean;
}
const dataMoreDetail = [
    {
        id: 1,
        text: "Once you click the button below, you’ll get 2 months of free access to all membership features immediately.",
    },
    {
        id: 2,
        text: "Billing for your previous membership will resume at the end of your free period.",
    },
    {
        id: 3,
        text: "You can cancel anytime before billing starts.",
    }
];

const MembershipOfferTwoMonthsModal = (
    { membershipId = "" }: IMembershipOfferTwoMonthsModalProps,
    ref: Ref<IMembershipOfferTwoMonthsModalRef>
) => {
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const history = useHistory();

    useHideExternalIcon(isVisible);

    const classes = useStyles();
    const { auth } = useContext(UserAppContext);
    const { data } = useSharedQuery();

    const [reactiveUserSubscriptionV2] =
        useReactiveUserSubscriptionV2Mutation();

    const membershipInvalidPaymentRef =
        useRef<IMembershipOfferInvalidPaymentModalRef>(null);

    const membershipClaimedRef = useRef<IMembershipOfferClaimedModalRef>(null);

    const stripeCustomerId = get(
        data,
        "currentUser.profile.stripe.customer_id"
    );

    const { data: customerData } = useRetriveStripeCustomerQuery({
        variables: {
            customerId: stripeCustomerId,
            params: {
                "expand[]": "invoice_settings.default_payment_method",
            },
        },
        fetchPolicy: "no-cache",
        skip: Boolean(!stripeCustomerId),
    });

    const cardInfo = useMemo(() => {
        if (!customerData || !customerData?.retriveStripeCustomer)
            return undefined;

        const defaultPaymentInfo =
            customerData?.retriveStripeCustomer?.invoice_settings
                ?.default_payment_method || undefined;

        if (defaultPaymentInfo) {
            return {
                exp_month: defaultPaymentInfo?.card?.exp_month,
                exp_year: defaultPaymentInfo?.card?.exp_year,
            };
        }
        return undefined;
    }, [customerData]);

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const handleClose = () => {
        setIsVisible(false);
    };

    const handleCheckCardExpired = ({
        expMonth,
        expYear,
    }: {
        expMonth: number;
        expYear: number;
    }) => {
        if (!expMonth || !expYear) return true;

        const currentDate = new Date();
        const currentMonth = currentDate.getMonth() + 1;
        const currentYear = currentDate.getFullYear();

        return (
            expYear < currentYear ||
            (expYear === currentYear && expMonth < currentMonth)
        );
    };

    const handleClickRedeemOffer = async () => {
        const isExpired = handleCheckCardExpired({
            expMonth: cardInfo?.exp_month,
            expYear: cardInfo?.exp_year,
        });
        if (isExpired) {
            setIsVisible(false);
            membershipInvalidPaymentRef?.current?.setIsVisible(true);
            return;
        }

        if (!auth?.email || Boolean(isSubmitting)) return;
        try {
            setIsSubmitting(true);

            await reactiveUserSubscriptionV2({
                variables: {
                    id: membershipId,
                },
            });
            setIsVisible(false);
            setIsSubmitting(false);

            membershipClaimedRef?.current?.setIsVisible(true);
        } catch (error) {
            setIsVisible(false);

            const isCardDeclined =
                error.graphQLErrors?.[0]?.key === "stripe_card_declined";
            if (isCardDeclined) {
                await Swal.fire(
                    "Oops",
                    `
            Your card was declined. Please update your payment methods and try again
            `,
                    "error"
                );
                history.push(`/payment_method`);
            } else {
                await Swal.fire(
                    "Oops",
                    `
            It seem like you’re not eligible for
            this offer.
            `,
                    "error"
                );
                localStorage.removeItem(MEMBERSHIP_OFFER_KEY);
                setIsSubmitting(false);
                window.location.reload();
            }
        }
    };

    useImperativeHandle(
        ref,
        () => ({
            isVisible,
            setIsVisible,
        }),
        [isVisible]
    );

    return (
        <>
            <Modal
                open={isVisible}
                onClose={handleClose}
                className={classes.modal}
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={isVisible}>
                    <div className={styles["membership_offer_modal"]}>
                        <div
                            className={styles["membership_offer_modal-header"]}
                        >
                            <IconButton
                                onClick={handleClose}
                                className="close-button"
                            >
                                <Close />
                            </IconButton>
                        </div>

                        <div
                            className={styles["membership_offer_modal-content"]}
                        >
                            <div className={styles["header"]}>
                                <div>
                                    <img
                                        src="/images/aviron-logo.png"
                                        alt="Aviron Logo"
                                        width={129}
                                        height={25}
                                        style={{
                                            objectFit: "contain",
                                        }}
                                    />
                                    <h2
                                        className={
                                            styles["membership_offer-title"]
                                        }
                                    >
                                        WELCOME BACK
                                        {data?.currentUser?.firstName &&
                                            ","}{" "}
                                        <span className={styles["highlight"]}>
                                            {data?.currentUser?.firstName || ""}
                                        </span>
                                    </h2>
                                    <p
                                        className={
                                            styles[
                                                "membership_offer-description"
                                            ]
                                        }
                                    >
                                        2 MONTHS <br />
                                        FREE
                                    </p>
                                </div>
                                <img
                                    src="/images/membership/membership-offer.webp"
                                    alt="Aviron Membership Offer"
                                    className={styles["membership_offer-image"]}
                                />
                            </div>
                            <div className={styles["content"]}>
                                <ul
                                    className={
                                        styles["membership_offer_more-info"]
                                    }
                                >
                                    {dataMoreDetail.map((item) => (
                                        <li
                                            className={
                                                styles[
                                                    "membership_offer_more-info-item"
                                                ]
                                            }
                                            key={item.id}
                                        >
                                            {item.text}
                                        </li>
                                    ))}
                                </ul>
                                <div
                                    className={
                                        styles["membership_offer-button"]
                                    }
                                >
                                    <Button
                                        className={styles["btn-claim-offer"]}
                                        variant="contained"
                                        onClick={handleClickRedeemOffer}
                                        color="secondary"
                                        sx={{
                                            marginTop: "24px",
                                            marginBottom: "8px",
                                            fontSize: "16px",
                                        }}
                                    >
                                        {isSubmitting
                                            ? "REDEEMING..."
                                            : "REDEEM OFFER"}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>
            <MembershipOfferInvalidPaymentModal
                ref={membershipInvalidPaymentRef}
            />
            <MembershipOfferClaimedModal
                ref={membershipClaimedRef}
                id={membershipId}
            />
        </>
    );
};

export default forwardRef(MembershipOfferTwoMonthsModal);
