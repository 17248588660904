import {
    Backdrop,
    Fade,
    Modal,
    Typography,
    Box,
    Paper,
    Button,
    Grid,
    Stack,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import CurrencyNumber from "Components/CurrencyNumber";
import React from "react";
import "./style.scss";

const useStyles: any = makeStyles({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "none",
    },
});

const planIntervalPriority: any = {
    monthly: 1,
    yearly: 2,
    lifetime: 3,
};

const ChangeMembershipModal: React.FunctionComponent<{
    open: boolean;
    onClose: any;
    state: any;
    onSubmit: (planId: number) => void;
    plans: any[];
    membership?: any;
}> = ({ open, onClose, onSubmit, state, plans, membership = {} }) => {
    const classes = useStyles();
    const { change_plan_invoice_url, processing_change_plan } = state;
    const [avaiblePlans, setAvaiablePlans] = React.useState<any[]>([]);
    const [selectedPlanId, setSelectedPlanId] = React.useState<number>(
        membership?.planId
    );
    const [itemPlan, setItemPlan] = React.useState<any>({});
    React.useEffect(() => {
        if (plans?.length && membership) {
            setSelectedPlanId(membership.planId);
            setItemPlan(membership.plan);
            const avPlans = plans.filter((plan) => {
                return (
                    plan.id !== membership.plan.id &&
                    plan.customerType === membership.plan.customerType &&
                    planIntervalPriority[plan.interval] >=
                    planIntervalPriority[membership.plan.interval] &&
                    plan.type === "stripe" &&
                    plan.enable &&
                    plan.currency === membership.plan.currency &&
                    plan.productId === membership.plan.productId &&
                    // && plan.productId !== "warranty"
                    Number(plan.amount) > Number(membership.plan.amount) &&
                    plan.maxMachines >= membership.plan.maxMachines 
                    && membership.plan.appIds.some((appId: number) => { return plan.appIds.includes(appId) })
                );
            });
            setAvaiablePlans(avPlans);
        }
    }, [membership, plans]);

    const handleSubmit = () => {
        onSubmit(selectedPlanId);
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            className={classes.modal}
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <div className="change-membership-modal-component">
                    <Box>
                        <Typography component="h3" variant="h3" mb="8px">
                            CHANGE MEMBERSHIP PLAN
                        </Typography>
                        <Typography>
                            {" "}
                            If you change your plan, you’ll be charged the
                            prorated difference between the two plans based on
                            the remaining time in your billing cycle.
                        </Typography>
                    </Box>
                    <div
                        style={{
                            minHeight: "50px",
                            maxHeight: "400px",
                            overflowY: "scroll"
                        }}
                    >
                        <Paper sx={{ padding: "32px 18px", margin: "32px 0" }}>
                            <Grid
                                container
                                display="flex"
                                justifyContent="space-between"
                            >
                                <Grid item>
                                    <Typography
                                        component="h3"
                                        variant="h4"
                                        mb="16px"
                                    >
                                        {itemPlan.name}
                                    </Typography>
                                    <Typography
                                        component="h3"
                                        variant="h5"
                                        color="#F5490F"
                                    >
                                        <CurrencyNumber value={itemPlan?.amount} />
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    textAlign="end"
                                    sx={{ fontFamily: "Work Sans" }}
                                >
                                    <i
                                        className="far fa-check-circle"
                                        style={{ color: "#f5490f" }}
                                    ></i>{" "}
                                    Current plan
                                </Grid>
                            </Grid>
                        </Paper>
                        {avaiblePlans.map((plan) => {
                            return (
                                <Paper
                                    key={plan.id}
                                    sx={{
                                        padding: "32px 18px",
                                        margin: "32px 0",
                                    }}
                                    onClick={setSelectedPlanId.bind(
                                        null,
                                        plan.id
                                    )}
                                    className={`select-box ${plan.id === selectedPlanId
                                            ? "select-box__active"
                                            : null
                                        }`}
                                >
                                    <Typography
                                        component="h4"
                                        variant="h4"
                                        mb="16px"
                                    >
                                        {plan.name}
                                    </Typography>
                                    <Typography
                                        component="h5"
                                        variant="h5"
                                        color="#F5490F"
                                    >
                                        <CurrencyNumber value={plan?.amount} />
                                    </Typography>
                                </Paper>
                            );
                        })}
                    </div>
                    {change_plan_invoice_url ? (
                        <p>
                            <br />
                            <br />
                            Can't charge your card. We will open a new tab
                            automatically to retry payment for membership.
                            Please reload page after payment successful. Please{" "}
                            <a
                                href={change_plan_invoice_url}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                CLICK HERE
                            </a>{" "}
                            if nothing happens.
                        </p>
                    ) : null}
                    <Stack
                        spacing={2}
                        direction={{ xs: "column-reverse", md: "row" }}
                        alignItems="end"
                        justifyContent="flex-end"
                        width={{
                            xs: "100%",
                            md: "60%",
                        }}
                        sx={{
                            float: "right",
                        }}
                    >
                        <Button
                            variant="outlined"
                            onClick={onClose}
                            size="medium"
                        >
                            EXIT
                        </Button>
                        <Button
                            disabled={selectedPlanId === itemPlan.id}
                            variant="contained"
                            color="secondary"
                            size="medium"
                            onClick={handleSubmit}
                        >
                            {processing_change_plan ? (
                                <i className="fa fa-circle-o-notch fa-spin"></i>
                            ) : (
                                "CHANGE MEMBERSHIP"
                            )}
                        </Button>
                    </Stack>
                </div>
            </Fade>
        </Modal>
    );
};

export default ChangeMembershipModal;
