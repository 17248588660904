import { Box, Button, Typography } from "@mui/material";
import GoBack from "Components/GoBack";
import React, { useMemo, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { END_FREE_TRIAL_END, NEXT_PLAN_UPGRADE } from "../UpgradePlan";
import moment from "moment";
import Swal from "sweetalert2";
import { useChangeUserSubscriptionPlanMutation } from "services/webServer";
import UpgradePlanModal from "../UpgradePlanModal";
import { IUpgradePlanModalRef } from "../UpgradePlan/UpgradePlanModal";

const UpgradePlanConfirm = () => {
    const params = useParams<{ id: string }>();
    const history = useHistory();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [changeUserSubscription] = useChangeUserSubscriptionPlanMutation();
    const upgradePlanModalRef = useRef<IUpgradePlanModalRef>(null);

    const upgradePlan = JSON.parse(
        sessionStorage.getItem(NEXT_PLAN_UPGRADE) as string
    );

    const isMonthly = useMemo(() => {
        if (Boolean(!upgradePlan)) return;
        return upgradePlan?.interval === "monthly";
    }, [upgradePlan]);

    const isTwoYear = useMemo(() => {
        if (Boolean(!upgradePlan)) return;
        return upgradePlan?.name?.includes("2 Year");
    }, [upgradePlan])

    const storedDate = sessionStorage.getItem(END_FREE_TRIAL_END);
    const endFreeTrialDate = storedDate
        ? moment(storedDate).format("MMM D, YYYY")
        : null;

    const handleChangePlan = async () => {
        const successFunc = function () {
            upgradePlanModalRef.current?.setIsVisible(true);
        };
        const errorFunc = async (
            message: string = "Change membership plan failed",
            isReload = true
        ) => {
            await Swal.fire("Error", message, "error");
            if (isReload){
                window.location.reload();
            }
        };
        try {
            if (!upgradePlan) {
                Swal.fire("Error", "newPlan undefined", "error");
                return;
            }
            setIsSubmitting(true);
            const { data } = await changeUserSubscription({
                variables: {
                    id: params?.id,
                    planId: upgradePlan?.id,
                },
            });
            if (
                data &&
                data.changeUserSubscriptionPlan &&
                (data as any).changeUserSubscriptionPlan.error
            ) {
                errorFunc(
                    "Can't charge your card. We will open a new tab automatically to retry payment for membership. Please reload page after payment successful."
                );

                setTimeout(() => {
                    window.open(
                        (data as any)?.changeUserSubscriptionPlan?.error.invoice
                            .hosted_invoice_url
                    );
                }, 3000);
            } else {
                successFunc();
            }
        } catch (error) {
            const isCardDeclined =
                error.graphQLErrors?.[0]?.key === "stripe_card_declined";
            if (isCardDeclined) {
                await errorFunc(
                    "Your card was declined. Please update your payment methods and try again",
                    false
                );
                history.push(`/payment_method`);
            } else {
                errorFunc();
            }
        } finally {
            setIsSubmitting(false);
        }
    };

    if (Boolean(!params?.id)) {
        history.push(`/membership`);
    }
    if (Boolean(!upgradePlan)) {
        history.push(`/membership/${params.id}/upgrade`);
    }

    return (
        <Box
            sx={{
                marginTop: "24px",
            }}
        >
            <GoBack />
            <Box
                sx={{
                    padding: {
                        xs: "12px",
                        md: "0px",
                    },
                    marginTop: {
                        xs: "12px",
                        md: "24px",
                    },
                }}
            >
                <Box
                    sx={{
                        backgroundColor: "#fff",
                        padding: {
                            xs: "16px 12px",
                            md: "40px",
                        },
                        borderRadius: "8px",
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: {
                                xs: "18",
                                md: "20px",
                            },
                            lineHeight: {
                                xs: "20px",
                                md: "24px",
                            },
                            fontWeight: 700,
                            fontFamily: "GRIFTER",
                            marginBottom: {
                                xs: "16px",
                                md: "24px",
                            },
                        }}
                    >
                        CONFIRM YOUR MEMBERSHIP
                    </Typography>
                    <Box
                        sx={{
                            borderRadius: "8px",
                            backgroundColor: "#F4F4F4",
                            display: "flex",
                            alignItems: {
                                xs: "flex-start",
                                md: "center",
                            },
                            justifyContent: "space-between",
                            flexDirection: {
                                xs: "column",
                                md: "row",
                            },
                            padding: {
                                xs: "12px",
                                md: "28px 24px",
                            },
                        }}
                    >
                        <Typography
                            sx={{
                                fontWeight: 600,
                                fontSize: {
                                    xs: "16px",
                                    md: "20px",
                                },
                                lineHeight: {
                                    xs: "20px",
                                    md: "24px",
                                },
                                marginBottom: {
                                    xs: "12px",
                                    md: "0",
                                },
                            }}
                        >
                            {upgradePlan?.name?.toUpperCase()}
                        </Typography>
                        <Typography
                            sx={{
                                fontWeight: 500,
                                fontFamily: "MonoSpec",
                                fontSize: {
                                    xs: "20px",
                                    md: "32px",
                                },
                                lineHeight: {
                                    xs: "25px",
                                    md: "40px",
                                },
                            }}
                        >
                            ${upgradePlan?.amount}.00
                        </Typography>
                    </Box>
                    <Typography
                        sx={{
                            marginTop: {
                                xs: "16px",
                                md: "24px",
                            },
                            fontSize: {
                                xs: "14px",
                                md: "16px",
                            },
                            fontWeight: 400,
                        }}
                    >
                        Starting on <b>{endFreeTrialDate}</b>, you'll be charged
                        a price of <b>${upgradePlan?.amount}</b> {" "}
                        {isTwoYear ? 'every 2 years' :isMonthly ? "each month" : "each year"}. Your plan will
                        automatically renew {isTwoYear ? 'every 2 years' : isMonthly ? "each month" : "each year"}{" "}
                        until canceled.
                    </Typography>
                    <Box
                        sx={{
                            textAlign: "end",
                        }}
                    >
                        <Button
                            sx={{
                                backgroundColor: "#FF4106",
                                "&:hover": {
                                    backgroundColor: "#FF4106",
                                },
                                fontWeight: 500,
                                fontSize: "16px",
                                lineHeight: "20px",
                                color: "#fff",
                                marginTop: {
                                    xs: "32px",
                                    md: "24px",
                                },
                                width: {
                                    xs: "100%",
                                    md: "240px",
                                },
                                cursor: Boolean(isSubmitting)
                                    ? "not-allowed"
                                    : "pointer",
                            }}
                            disabled={Boolean(isSubmitting)}
                            onClick={handleChangePlan}
                        >
                            {isSubmitting ? "PROCESSING..." : "CONFIRM"}
                        </Button>
                    </Box>
                </Box>
            </Box>
            <UpgradePlanModal ref={upgradePlanModalRef} />
        </Box>
    );
};

export default UpgradePlanConfirm;
