import { useEffect } from "react";
import { hideExternalIcons } from "utils/handleHideExternalIcon";

export const useHideExternalIcon = (isVisible: boolean) => {
    useEffect(() => {
        const isMobile = window.innerWidth < 768;
        if (!isMobile) return;
        if (isVisible) {
            const iframeDom = document.getElementsByTagName("iframe");
            hideExternalIcons(iframeDom);
        } else {
            const iframeDom = document.getElementsByTagName("iframe");
            if (iframeDom) {
                for (let i = 0; i < iframeDom.length; i++) {
                    iframeDom[i]?.classList?.remove("hideExternalLink");
                }
            }
        }
    }, [isVisible]);
};