import { ENV } from "scripts/environment";

export const handleCheckIsFreeMembership = (planId: number) => {
    if (!planId) return false;
    const isFreeMembership = [
        ENV.MEMBERSHIP.FREE_6_MONTH_GAME_PLAN_ID,
        ENV.MEMBERSHIP.FREE_1_MONTH_GAME_PLAN_ID,
    ].some((plan) => Number(plan) === Number(planId));
    return isFreeMembership;
};
