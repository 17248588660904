import { Session } from "@heroiclabs/nakama-js";
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse, HttpStatusCode } from "axios";
import moment from "moment";
import Swal from "sweetalert2";

export type GameEnviroment = "dev" | "alpha";

export const saveTokenToLocalStorage = (token: string) => {
    localStorage.setItem("gsvTokenGame", token);
};

export const getTokenFromLocalStorage = () => {
    const result = localStorage.getItem("gsvTokenGame") || "";
    return result || "";
};

export function isAxiosError(error: unknown): error is AxiosError {
    // eslint-disable-next-line import/no-named-as-default-member
    return axios.isAxiosError(error);
}

export function isAxiosUnauthorizeError<UnauthorizeError>(
    error: unknown
): error is AxiosError<UnauthorizeError> {
    return (
        isAxiosError(error) &&
        error.response?.status === HttpStatusCode.Unauthorized
    );
}

export class BaseGameServerAPI {
    key: string;
    httpKey: string;
    host?: string;
    port: string = process.env.REACT_APP_GAME_SV_PORT as string;
    useSSL: boolean = true;
    exp: number | undefined;
    uid: string | undefined;
    instance: AxiosInstance;
    private token: string;
    private app_id: string | undefined;
    private controller: AbortController;
    constructor() {
        this.token = getTokenFromLocalStorage();
        this.host = process.env.REACT_APP_GAME_SV_URL as string;
        this.key = process.env.REACT_APP_GAME_SV_KEY as string;
        this.httpKey = process.env.REACT_APP_GAME_HTTP_KEY as string;
        this.controller = new AbortController();

        this.instance = axios.create({
            baseURL: `https://${this.host}/v2/rpc`,
            timeout: 10000,
            headers: {
                "Content-Type": "application/json",
            },

            params: {
                // http_key: this.httpKey,
                unwrap: true,
            },

            signal: this.controller.signal,
        });

        this.instance.interceptors.request.use(
            async (config) => {
                let expires_at = 0;
                this.token = getTokenFromLocalStorage();
                const idToken = localStorage.getItem("idToken") || "";

                if (this.token) {
                    expires_at = Session.restore(this.token).expires_at;
                    config.headers.Authorization = `Bearer ${this.token}`;
                }
                if (!this.token || moment.unix(expires_at).isBefore(moment())) {
                    const gsvToken = await this.getDeviceToken(idToken, "");

                    this.token = gsvToken as string;
                    saveTokenToLocalStorage(gsvToken as string);
                    config.headers.Authorization = `Bearer ${this.token}`;
                }
                if(this.app_id !== undefined) {
                    config.headers.app_id = this.app_id;
                    delete config.headers.Authorization;
                }
            
                return config;
            },
            function (error) {
                return Promise.reject(error);
            }
        );

        this.instance.interceptors.response.use(
            (response) => {
                return response;
            },
            async (error: AxiosError) => {
                // We will handle redirect to the login page if status code is 401
                if (isAxiosUnauthorizeError<any>(error)) {

                    localStorage.clear();
            
                    this.redirectPage();
                    return error;
                }
                if (error) return Promise.reject(error);
            }
        );
    }

    async getDeviceToken(token_id: string, app_id: string | null) {
        let dataFormatted = {
            id: token_id,
        };
        if (Boolean(app_id)) {
            dataFormatted = { ...dataFormatted, vars: { app_id: app_id } } as {
                id: string;
                vars: { app_id: string | null };
            };
        }
        try {
            const options: AxiosRequestConfig = {
                url: `https://${this.host}/v2/account/authenticate/custom`,
                method: "post",
                headers: {
                    "Content-Type": "application/json",
                },
                auth: {
                    username: this.key,
                    password: "",
                },
                data: dataFormatted,
                signal: this.controller.signal,
            };

            const { data } = await this.exec(options) as AxiosResponse;
            const session = Session.restore(data.token);

            if (session.token) {
                localStorage.setItem(
                    "appId",
                    (session?.vars as any)?.app_id || ""
                );
                this.token = session.token;

                return session.token;
            }
        } catch (err) {
            this.redirectPage();
        }
    }

    redirectPage = async () => {
        // this.isErroring = true

        await Swal.fire(
            "Please log in",
            "We will redirect you back to our login page where you can log into your account.",
            "info"
        );
        // window.location.href = `${ENV.STOREFRONT_DOMAIN}?redirect=${window.location.href}`;
        window.location.href = `/login?redirect=${window.location.href}`;
    };

    async exec(options: AxiosRequestConfig) {
        return await axios({ ...options });
    }
    public async setAppId(appId: string | undefined) {
        this.app_id = appId;
    }
}

const http = new BaseGameServerAPI().instance;

export default http;
