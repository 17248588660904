import { useEffect, useState } from "react";
import {
    useGetAvironSubscriptionQuery,
    useSharedQuery,
} from "services/webServer";
import { gameServerApi } from "services/gameServerV2";
import { useHistory } from "react-router-dom";
import { CONSTANTS } from "consts";

export default function useGetMembershipDetail(membershipId: string) {
    const history = useHistory();
    const [gameMembershipDetail, setGameMembershipDetail] = useState(null);
    const [isFetchingGameMembership, setIsFetchingGameMembership] =
        useState(false);
    const { data: userData } = useSharedQuery(); // should fetch every time this hook
    const {
        data,
        loading,
        error,
        refetch: refetchWebMembership,
    } = useGetAvironSubscriptionQuery({
        variables: {
            id: membershipId,
        },
        fetchPolicy: "no-cache",
        notifyOnNetworkStatusChange: true,
    });

    useEffect(() => {
        console.log(error)
        // TODO: handle exception
    }, [error])

    useEffect(() => {
        getGameMembership();
    }, [userData]);

    const getGameMembership = async () => {
        setIsFetchingGameMembership(true);
        try {
            const res = await gameServerApi.user.getMembershipFamilly({
                orgId: membershipId,
            });
            const membership = res.data.currentMembership;
            const isOwnerOfMembership =
                membership?.owner?.customId === userData?.currentUser?.id;
            setGameMembershipDetail({ ...membership, isOwnerOfMembership });
        } catch (error) {
            if (error.response.status === 404) {
                history.push(CONSTANTS.NAVIGATION_URL.NOT_FOUND);
            } else {
                //TODO: show error toast
            }
        } finally {
            setIsFetchingGameMembership(false)
        }
    };

    const refetch = () => {
        return Promise.all([refetchWebMembership(), getGameMembership()]);
    };

    return {
        gameMembershipDetail,
        webMembershipDetail: data?.avironSubscription,
        refetch,
        loading: loading || isFetchingGameMembership,
    };
}
