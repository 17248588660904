import Box from "@mui/material/Box";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import CurrencyNumber from "Components/CurrencyNumber";
import * as React from "react";
import { mobileDetect } from "scripts/mobileDetect";
import ReceiptCard from "./ReceiptCard";
import style from './Receipts.module.scss';

const columns = [
    { field: "date", headerName: "Date", width: 130 },
    { field: "periodStart", headerName: "Period start", width: 130 },
    { field: "periodEnd", headerName: "Period end", width: 130 },
    { field: "amount", headerName: "Amount", width: 130 },
    { field: "status", headerName: "Status", width: 130 },
];

const Receipts: React.FunctionComponent<{
    receipts: any[];
    totalCount: number;
    onPageChange: Function;
    pageSize: number;
    currentPage: number;
}> = ({ receipts, totalCount, onPageChange, pageSize, currentPage }) => {
    const isMobile = mobileDetect();
    const totalPage = Math.ceil(totalCount / pageSize);
    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        onPageChange(value - 1);
    };
    const rowItemStyle = (index: number) => ({
        fontSize: "13px",
        borderBottom:
            index < receipts.length - 1 ? "solid 0.5px #808080 !important" : "",
    });
    return (
        <Box className={style['ReceiptsWrapper']}>
            {isMobile ? (
                <Box mt="16px">
                    {receipts.map((receipt, index) => {
                        return <ReceiptCard key={index} {...receipt} index={index} />;
                    })}
                </Box>
            ) : (
                <TableContainer>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                {columns.map((collum, index) => {
                                    return (
                                        <TableCell
                                            key={index}
                                            sx={{
                                                paddingLeft:
                                                    index === 0 ? 0 : undefined,
                                                borderBottom:
                                                    "solid 0.5px #808080 !important",
                                            }}
                                        >
                                            <Typography variant="subtitle2">
                                                {collum.headerName}
                                            </Typography>
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {receipts.map((row, index) => (
                                <TableRow
                                    key={index}
                                    sx={{
                                        "&:last-child td, &:last-child th": {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell
                                        align="left"
                                        sx={{
                                            ...rowItemStyle(index),
                                            paddingLeft: 0,
                                        }}
                                    >
                                        {row.createdAt}
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        sx={rowItemStyle(index)}
                                    >
                                        {row.periodStart}
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        sx={rowItemStyle(index)}
                                    >
                                        {row.periodEnd}
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        sx={rowItemStyle(index)}
                                    >
                                        <CurrencyNumber value={row.amount} />{row.isShowStar === true && <>*</>}
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        sx={rowItemStyle(index)}
                                    >
                                        {row.status}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
            {totalPage > 1 ? (
                <Stack spacing={2} width="100%" alignItems="center" mt="32px">
                    <Pagination
                        count={totalPage}
                        color="secondary"
                        onChange={handleChange}
                        page={currentPage + 1}
                    />
                </Stack>
            ) : null}
        </Box>
    );
};

export default Receipts;
