import { Box } from "@mui/material";
import React, { useContext, useMemo } from "react";
import Parameter from "./Parameter";
import "./PataramerSection.scss";
import isEmpty from "lodash/isEmpty";
import { CONSTANTS } from "consts";
import { UserAppContext } from "contexts";
import {
    convertKilometerToMile,
    convertMeterToFit,
    convertMeterToKilometer,
    convertMeterToMile,
} from "utils/convertMeasure";
import { convertedSecondToTime, convertedSecondToTimeV2 } from "consts/dateTime";

type propsType = {
    workoutDetail: any;
    workoutMetrics: {
        totalWorkouts: number;
        rank: number;
        totalTime: number;
        totalActiveTime: number;
        totalMeter: number;
        totalOutput: number;
        totalWatt: number;
        totalCalories: number;
        totalStrokes: number;
        avgWatt: number;
        avgCalpHour: number;
        avgSecp500: number;
        avgSPM: number;
        maxSPM?: number;
        avgSpeed?: number;
        avgIncline?: number;
        elevationGain?: number;
    };
    isExpand?: boolean;
    loading?: boolean;
    unitMetric?: UnitMetric;
    mode?: "detailPage" | "overviewPage";
};

enum UnitMetric {
    meter = "meter",
    mile = "mile",
    kilometer = "kilometer",
}

export const defaulParameters = {
    totalWorkouts: 0,
    rank: 0,
    totalTime: 0,
    totalActiveTime: 0,
    totalMeter: 0,
    totalOutput: 0,
    totalWatt: 0,
    totalCalories: 0,
    totalStrokes: 0,
    avgWatt: 0,
    avgCalpHour: 0,
    avgSecp500: 0,
    avgSPM: 0,
    maxSPM: 0,
    avgSpeed: 0,
    avgIncline: 0,
    elevationGain: 0,
};
const MILE_TO_METER: number = 1609.34;

const ParameterSection: React.FunctionComponent<propsType> = ({
    workoutDetail = { rank: 0 },
    workoutMetrics = defaulParameters,
    loading,
    unitMetric = "meter",
    mode,
}) => {
    const { workoutState } = useContext(UserAppContext);

    const appId = Number(localStorage.getItem("appId"));
    const isC2BikeergMachine =
        Number(appId) === CONSTANTS.SWITCH_MACHINE.c2Bike.id;
    const isApkBikeergMachine =
        Number(appId) === CONSTANTS.SWITCH_MACHINE.bike.id;
    const isAvironBike = appId === CONSTANTS.APP.AVIRON_BIKE;
    const isAvironTreadmill = appId === CONSTANTS.APP.AVIRON_TREADMILL;

    const convertedTime = (time: number, toHour?: boolean) => {
        if (isAvironBike || isAvironTreadmill) {
            if (toHour) {
                if (!time) return "00:00:00";
                let hour = Math.floor(time / 3600);
                let minute = Math.floor(time / 60) % 60;
                let second = Math.floor(time % 60);
                if (second === 60) {
                    minute++;
                    second = 0;
                }
                if (minute === 60) {
                    hour++;
                    minute = 0;
                }
                return [hour, minute, second]
                    .map((item) => (item < 10 ? `0${item}` : String(item)))
                    .join(":");
            }

            if (!time) return "00:00";
            let minute = Math.floor(time / 60);
            let second = Math.floor(time % 60);
            if (second === 60) {
                minute++;
                second = 0;
            }
            const minuteStr =
                minute >= 10 ? String(minute) : `0${String(minute)}`;
            const secondStr =
                second >= 10 ? String(second) : `0${String(second)}`;
            return `${minuteStr}:${secondStr}`;
        } else {
            if (toHour) {
                if (!time) return "00:00:00";
                let hour = Math.floor(time / 3600);
                let minute = Math.floor(time / 60) % 60;
                let second = Math.round(time % 60);
                if (second === 60) {
                    minute++;
                    second = 0;
                }
                if (minute === 60) {
                    hour++;
                    minute = 0;
                }
                return [hour, minute, second]
                    .map((item) => (item < 10 ? `0${item}` : String(item)))
                    .join(":");
            }

            if (!time) return "00:00";
            let minute = Math.floor(time / 60);
            let second = Math.round(time % 60);
            if (second === 60) {
                minute++;
                second = 0;
            }
            const minuteStr =
                minute >= 10 ? String(minute) : `0${String(minute)}`;
            const secondStr =
                second >= 10 ? String(second) : `0${String(second)}`;
            return `${minuteStr}:${secondStr}`;
        }
    };

    const getTime = useMemo(() => {
        if (loading) return 0;
        return workoutDetail.id
            ? Math.floor(workoutDetail.activeTime)
            : Math.floor(workoutMetrics.totalActiveTime);
    }, [workoutDetail, workoutMetrics]);

    const handleDisplayScore = () => {
        if (loading) return "--";
        if (workoutDetail.scoreTitle === "SCORE") {
            return workoutDetail?.scoreValue || "--";
        }
        const rank = workoutDetail?.scoreValue.slice(-2);
        const value = workoutDetail?.scoreValue.slice(
            0,
            workoutDetail?.scoreValue.length - 2
        );
        return (
            <p style={{ margin: 0 }}>
                {value || "--"}
                <sup>{rank}</sup>
            </p>
        );
    };

    const getFirstMetric = () => {
        if (isEmpty(workoutDetail)) {
            return {
                title: "WORKOUTS",
                display: workoutMetrics.totalWorkouts || "0",
            };
        }
        return {
            title: workoutDetail?.scoreTitle,
            display: handleDisplayScore(),
        };
    };

    const fourthValue = useMemo(() => {
        switch (workoutState.currentUnit) {
            case "miles":
                return {
                    title: "MILES",
                    value: convertMeterToMile(
                        workoutMetrics.totalMeter,
                        100,
                        true
                    ),
                };
            case "kilometers":
                return {
                    title: "KILOMETERS",
                    value: convertMeterToKilometer(
                        workoutMetrics.totalMeter,
                        100,
                        true
                    ),
                };
            default:
                return {
                    title: "METERS",
                    value: Math.round(workoutMetrics.totalMeter || 0),
                };
        }
    }, [workoutMetrics.totalMeter, workoutState.currentUnit]);

    const fifthValue = useMemo(() => {
        let title = "STROKES";
        let value;
        switch (appId) {
            case CONSTANTS.SWITCH_MACHINE.bike.id:
                title = "MAX. RPM";
                value =
                    mode === "detailPage"
                        ? workoutMetrics?.totalStrokes
                        : workoutMetrics.maxSPM;
                break;
            case CONSTANTS.SWITCH_MACHINE.c2Bike.id:
                title = "REVOLUTIONS";
                value = workoutMetrics.totalStrokes;
                break;
            case CONSTANTS.APP.AVIRON_TREADMILL:
                    if(workoutState.currentUnit === 'miles') {
                        title = "ELEVATION GAIN (FT)";
                        value = convertMeterToFit(workoutMetrics?.elevationGain || 0);
                    } else {
                        title = "ELEVATION GAIN (M)";
                        value = Math.floor(workoutMetrics?.elevationGain || 0);
                    }
                    break;
            default:
                value = workoutMetrics.totalStrokes;
                break;
        }
        return {
            title: title,
            value: value,
        };
    }, [workoutMetrics, workoutState.currentUnit]);

    const eigthValue = useMemo(() => {
        let title = "AVG. CALS/HOUR";
        let value;
        switch (appId) {
            case CONSTANTS.APP.AVIRON_TREADMILL:
                    title = "AVG. INCLINE (%)";
                    value = (
                        Math.floor((workoutMetrics?.avgIncline || 0) * 10) / 10
                    ).toLocaleString("en", { minimumFractionDigits: 1 });
                    break;
            default:
                value = Math.round(workoutMetrics.avgCalpHour);
                break;
        }
        return {
            title: title,
            value: value,
        };
    }, [workoutMetrics]);

    const ninethValue = useMemo(() => {
        console.log('ccccworkoutMetrics', workoutMetrics);
        switch (workoutState.currentUnit) {
            case "miles":
                return {
                    title: "AVG. MPH",
                    value: convertKilometerToMile(
                        workoutMetrics?.avgSpeed || 0,
                        10
                    ),
                };
            case "kilometers":
                return {
                    title: "AVG. KPH",
                    value: (
                        Math.floor((workoutMetrics?.avgSpeed || 0) * 10) /
                        10
                    ).toLocaleString("en", { minimumFractionDigits: 1 }),
                };
            default:
                return {
                    title: "AVG. SPLIT",
                    value: convertedTime(workoutMetrics?.avgSecp500 || 0),
                };
        }
    }, [workoutMetrics, workoutState.currentUnit]);

    const tenValue = useMemo(() => {
        let title = "AVG. SPM";
        let value;
        switch (appId) {
            case CONSTANTS.SWITCH_MACHINE.c2Bike.id:
                title = "AVG. RPM";
                value = Math.round(workoutMetrics?.avgSPM);
                break;
            case CONSTANTS.SWITCH_MACHINE.bike.id:
                title = "AVG. RPM";
                value = Math.round(workoutMetrics?.avgSPM);
                break;
            case CONSTANTS.SWITCH_MACHINE.treadmill.id:
                title = "AVG. PACE";
                value =
                    workoutState.currentUnit === "miles"
                        ? convertedSecondToTime(workoutMetrics?.avgSPM / CONSTANTS.UNIT_CONVERT.KILOMETER_CONVERT_TO_MILE_VALUE)
                        : convertedSecondToTime(workoutMetrics?.avgSPM);
                break;
            default:
                value = Math.round(workoutMetrics.avgSPM);
                break;
        }
        return {
            title: title,
            value: value,
        };
    }, [workoutMetrics, workoutState.currentUnit]);

    const firstMetric = getFirstMetric();
    return (
        <Box className="parameter-section-component">
            <Box className="parameter-container">
                <Parameter
                    title={firstMetric.title}
                    parameter={firstMetric.display}
                    className={"scoreParam"}
                />
                <Parameter
                    title={"OUTPUT (KJ)"}
                    parameter={Math.floor(workoutMetrics.totalOutput || 0)}
                    className={"outputParam"}
                />
                <Parameter
                    title={"CALORIES"}
                    parameter={Math.floor(workoutMetrics.totalCalories || 0)}
                    className={"caloriesParam"}
                />
                <Parameter
                    title={fourthValue.title}
                    parameter={fourthValue.value}
                    className={"metersParam"}
                />
                <Parameter
                    title={fifthValue.title}
                    parameter={fifthValue.value || 0}
                    className={"strokesParam"}
                />
                <Parameter
                    title={"TIME"}
                    parameter={convertedSecondToTimeV2(getTime, true)}
                    className={"timeParam"}
                />
                <Parameter
                    title={"AVG. WATT"}
                    parameter={Math.round(workoutMetrics.avgWatt || 0)}
                    className={"avgWattParam"}
                />
                <Parameter
                    title={eigthValue.title}
                    parameter={eigthValue.value || 0}
                    className={"avgCalsParam"}
                />
                <Parameter
                    title={ninethValue.title}
                    parameter={ninethValue.value}
                    className={"avg500MParam"}
                />
                <Parameter
                    title={tenValue.title}
                    parameter={tenValue?.value || 0}
                    className={"avgSpmParam"}
                />
            </Box>
        </Box>
    );
};
export default ParameterSection;
