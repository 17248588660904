import { Paper, Grid, Typography } from "@mui/material";
import React from "react";
import { styled } from "@mui/styles";

const CardFieldTitle = styled(Typography)(({ theme }) => ({
    fontWeight: "500 !important",
    color: "#939393 !important",
    fontFamily: "Work Sans !important",
    fontSize: "14px !important",
}));
const CardFieldContent = styled(Typography)(({ theme }) => ({
    fontFamily: "Work Sans !important",
    fontSize: "14px !important",
}));

const MembershipCard: React.FunctionComponent<{
    id: string;
    token: string;
    machineId: string;
    model: string;
    startAt: string;
    nextBillingDate: string;
    status: string;
    name: string;
    stateColors: any;
    state: string;
    onClickDetail: Function;
    appType: string;
}> = ({
    id,
    token,
    machineId,
    model,
    startAt,
    nextBillingDate,
    status,
    name,
    stateColors,
    state,
    onClickDetail,
    appType,
}) => {
    return (
        <Paper
            sx={{
                borderRadius: "8px",
                minHeight: "184px",
                padding: "16px",
                margin: "16px 0",
            }}
            onClick={onClickDetail.bind(null, id, appType)}
        >
            <Grid container justifyContent="space-between">
                <Grid item>
                    <Typography
                        sx={{
                            color: stateColors
                                ? stateColors.find((color: any) => {
                                      return color.id === state;
                                  })?.color
                                : "",
                        }}
                        mb="8px"
                    >
                        {status}
                    </Typography>
                    <Typography>{name}</Typography>
                </Grid>
                <Grid item>{/* <Button>Change</Button> */}</Grid>
            </Grid>
            <Grid container spacing={1} mt="16px">
                <Grid item xs={6}>
                    <CardFieldTitle>Membership key</CardFieldTitle>
                    <CardFieldContent>{token}</CardFieldContent>
                </Grid>
                {/* <Grid item xs={5}>
                    <CardFieldTitle>Machine ID</CardFieldTitle>
                    <CardFieldContent>{machineId}</CardFieldContent>
                </Grid> */}
                <Grid item xs={6}>
                    <CardFieldTitle>Model</CardFieldTitle>
                    <CardFieldContent>{model}</CardFieldContent>
                </Grid>
                <Grid item xs={6}>
                    <CardFieldTitle>Activated</CardFieldTitle>
                    <CardFieldContent>{startAt}</CardFieldContent>
                </Grid>
                <Grid item xs={6}>
                    <CardFieldTitle>Next billing date</CardFieldTitle>
                    <CardFieldContent>{nextBillingDate}</CardFieldContent>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default MembershipCard;
